
import React from "react"
import styled from '@emotion/styled';
import { Link } from "gatsby";

import Layout from "../components/layout";
import Button from "../components/common/button";
import Features from "./featuresContent";
import translate from "../utils/translation";
import cloud from '../../static/images/cloud.svg';
import video from '../../static/images/sampleVideo.mp4';
import { MainTitle } from './common/title';
import { Helmet } from "react-helmet";
import { getOS, download } from '../utils/browser';

const Wrapper = styled.section`
  &.home {
    background: url(${cloud}) right 200px no-repeat, linear-gradient(to top, #FBFAFA, #F5EDEF, #E4F0FC);
  }

  &.features {
    padding-top: 50px;
    background: linear-gradient(to top, #FBF9F9, #FBFAFA);
  }
`;

const HomeContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 300px;

  @media (max-width: 990px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 760px) {
    padding-top: 200px;
    height: auto;
  }
`;

const Caption = styled.p``;

const ButtonContainer = styled.div`
  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }

  button {
    cursor: pointer;

    &:first-child {
      @media (max-width: 760px) {
        width: 235px;
        height: 48px;
        margin: 0 0 16px;
      }
    }

    &:nth-child(2) {
      @media (max-width: 760px) {
        width: 258px;
        height: 48px;
        margin: 0;
      }
    }
  }
`;

const DescTitle = styled.h3`
  font-size: 20px;
  margin: 0 0 16px;
`;

const DescText = styled.p`
  margin: 0;
  padding: 0;
  line-height: 1.5;

  @media (max-width: 760px) {
    line-height: 1.88;
  }
`;

const Descriptions = styled.div`
  display: flex;
  padding: 100px 0;

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 70px 0 0;
  }
`;

const DescriptionContainer = styled.div`
  width: 50%;
  padding-right: 60px;

  &:last-child {
    padding-left: 60px;
    padding-right: 0;
  }

  @media (max-width: 760px) {
    width: 100%;
    padding: 0 0 60px;

    &:last-child {
      padding-left: 0;
    }
  }
`;

const ShortDescription = ({ title, text }) => (
  <DescriptionContainer>
    <DescTitle>{translate(title)}</DescTitle>
    <DescText>{translate(text)}</DescText>
  </DescriptionContainer>
);

const LineBreak = styled.div`
  max-width: 1000px;
  height: 2px;
  background-color: #e6e6e6;
  border-radius: 3px;
  margin: 0 auto;
`;

const VideoWrapper = styled.div`
  max-width: 1006px;
  margin: 0 auto 170px;

  @media (max-width: 990px) {
    padding: 0 24px;
  }
`;

const VideoContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  outline: none;
`;

const GetStartedContainer = styled.section`
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(${cloud}) bottom right no-repeat, linear-gradient(to top, #E2EAE6, #FBF9F9);
  background-size: 90%;

  button {
    display: block;
    margin: 0 auto;
  }
`;

const GetStartedTitle = styled.h2`
  margin: 0 0 24px;
  padding: 0;
  font-size: 30px;
  color: #2f2f2f;
  letter-spacing: 0.8px;
  font-weight: 700;
  text-align: center;
`;

export default class Home extends React.Component {

  constructor() {
    super();
    this.handleScroll = this.handleScroll.bind(this);
    this.lineBreak = React.createRef();
    this.state = {
      activeIndex: 0,
    };
  }

  handleScroll() {
    let element = this.lineBreak.current.getBoundingClientRect();
    if (element != null) {
      let elementBottom = element.bottom;
      let windowHeight = window.innerHeight;
      this.setState({ activeIndex: elementBottom <= windowHeight ? 1 : 0 });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const os = getOS();
    return (
      <Layout activeTabIndex={this.state.activeIndex}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>No Brain Writing</title>
          <script type="text/javascript">
            {`
              <!-- Dashly BEGIN -->
              !function(){function t(t,e){return function(){window.dashlyasync.push(t,arguments)}}if("undefined"==typeof dashly){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="//cdn.dashly.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.dashly={},window.dashlyasync=[],dashly.settings={};for(var n=["connect","track","identify","auth","oth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)dashly[n[a]]=t(n[a])}}(),dashly.connect("1712-d84e20ed6145b5b91c2982618f1");
              <!-- Dashly END -->
            `}
          </script>
        </Helmet>
        <Wrapper className="home">
          <HomeContainer>
            <MainTitle>{translate("freeYourMind")}</MainTitle>
            <Caption>{translate("caption")}</Caption>
            <ButtonContainer>
              {
                (os === 'Mac OS' || os === 'Windows') &&
                <Button className="solid" width="177px" height="33px" onClick={() => download(os)}>{translate("getStarted")}</Button>
              }
              <Button width="177px" height="33px">
                <Link to="/about/">{translate("whatAreMorningPages")}</Link>
              </Button>
            </ButtonContainer>
            <Descriptions>
              <ShortDescription title="changeYourLife" text="changeYourLifeDesc" />
              <ShortDescription title="newHabit" text="newHabitDesc" />
            </Descriptions>
          </HomeContainer>
          <LineBreak ref={this.lineBreak} />
        </Wrapper>
        <Wrapper className="features" id="features">
          {/* <VideoWrapper>
            <VideoContainer>
              <Video src={video} controls />
            </VideoContainer>
          </VideoWrapper> */}
          <Features />
        </Wrapper>
        <GetStartedContainer>
          <GetStartedTitle>{translate("findYourCreativity")}</GetStartedTitle>
          {
            (os === 'Mac OS' || os === 'Windows') &&
            <Button className="solid" width="153px" height="32px" onClick={() => download(os)}>{translate("getStarted")}</Button>
          }
        </GetStartedContainer>
      </Layout>
    );
  }
}
