import styled from "@emotion/styled";

export default styled.button`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 30px;
  border: solid 1px #f38f8f;
  margin-right: 24px;
  color: #f38f8f;
  line-height: 1.42;
  font-size: 12px;
  letter-spacing: 0.3px;
  background: none;
  outline: none;

  &.solid {
    background: #f38f8f;
    color: #ffffff;
  }
`;