exports.getOS = () => {
    const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : '';
    const platform = typeof window !== 'undefined' ? window.navigator.platform : '';
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}

exports.download = (os) => {
    let downloadLink = 'https://no-brain-writing-download-desktop-app.s3.eu-central-1.amazonaws.com/No+Brain+Writing.dmg';
    if (os === 'Windows') {
      downloadLink = 'https://no-brain-writing-download-desktop-app.s3.eu-central-1.amazonaws.com/No+Brain+Writing+Setup.exe';
    }
    typeof window !== 'undefined' && window.location.assign(downloadLink);
  }
