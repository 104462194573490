import styled from "@emotion/styled";

export const MainTitle = styled.h1`
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 1px;
  font-size: 40px;
  line-height: 1.2;

  @media (max-width: 760px) {
    font-size: 32px;
    line-height: 1.5;
    margin-bottom: 24px;
  }
`;